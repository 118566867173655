<template>
  <div class="app-container">
    <h4 class="title">{{$route.query.Name?('搜索专业：'+$route.query.Name):$route.query.CategoryName}}</h4>
    <ul class="content">
      <li class="content-list" @click="openDetail(item)" v-for="(item, index) in mainData.Data" :key="index">
        <div class="content-list-name">{{item.SpecialtyName}}</div>
        <div class="content-list-describe">{{item.SpecialtyIntroduce}}</div>
        <div class="content-list-code" v-if="$route.query.Name">专业代码：{{item.SpecialtyCode}}</div>
      </li>
    </ul>
    <div>
      <custom-pagination
        :pageIndex="query.PageIndex"
        :pageSize="query.PageSize"
        :total="mainData.Total"
        @handleCurrentChange="turnPage"
      ></custom-pagination>
    </div>
  </div>
</template>

<script>
  // import { SpecialtyList, SpecialtySearch } from '@/api/search_specialty'
  import API from '@/api/config'
  export default {
    name: "specialty-list",
    data(){
      return{
        mainData: {},
        query: {
          CategoryCode:this.$route.query.CategoryCode,
          Name:this.$route.query.Name,
          PageIndex: 1,
          PageSize: 10
        }
      }
    },
    mounted(){
      this.initialize()
    },
    methods:{
      //打开详情
      openDetail(item){
        this.$router.push({
          path: '/specialty-detail',
          query: {
            SpecialtyCode: item.SpecialtyCode
          }
        })
      },
      //翻页
      turnPage(currentPage){
        this.query.PageIndex = currentPage
        this.getCategoryList()
      },
      //初始化数据
      initialize(){
        this.getCategoryList()
      },
      //获取列表数据
      async getCategoryList () {
        if(this.query.Name){
          this.$delete(this.query, 'CategoryCode')
          this.mainData = (await API.Specialty.SpecialtySearch(this.query)).data

          this.mainData.Total = Number(this.mainData.Total)

        }else{
          this.$delete(this.query, 'Name')
          this.mainData = (await API.Specialty.SpecialtyList(this.query)).data
          this.mainData.Total = Number(this.mainData.Total)
        }
      }
    }
  }
</script>

<style scoped lang="less">
  .title{
    color: rgba(var(--themecolor),1);
    font-size: var(--title-font-size);
  }
  .content{
    min-height: 90vh;
    margin-top: 2rem;
    &-list{
      margin-bottom: 2rem;
      padding: 1.5rem 1rem;
      background-color: #fff;
      &:hover{
        cursor: pointer;
        .content-list-name{
          color: rgba(var(--themecolor),1);
        }
      }
      &-name{
        font-weight: 700;
        color: var(--text-font-color);
        transition: color .2s linear;
      }
      &-describe{
        line-height: 1.5;
        margin-top: .5rem;
        font-size: var(--text-font-size);
        color: var(--text-font-color);
      }
      &-code{
        padding: 1rem 0;
        color: var(--text-font-color);
      }
    }
  }

</style>
<style lang="less">
.el-pagination.is-background .el-pager li:not(.disabled).active{
  background-color: rgba(var(--themecolor),0.75);
}
.el-pagination.is-background .el-pager li:not(.disabled):hover{
  color: rgba(var(--themecolor),0.75);
  background-color: rgba(var(--themecolor),0.2);
}
.el-pagination.is-background .el-pager li:not(.disabled).active:hover{
  color: #FFF;
  background-color: rgba(var(--themecolor),0.75);
}
.el-pagination.is-background .btn-next:hover, .el-pagination.is-background .btn-prev:hover, .el-pagination.is-background .el-pager li:hover{
  background-color: rgba(var(--themecolor),0.2);
}
</style>